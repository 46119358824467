<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Address book category </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Address book category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createAddressBookCategory"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add address book Category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                    label="Title"
                    v-model="search.title"
                    v-on:keyup.enter="searchAddressBookCategory"
                    @input="search.title = $event !== null ? $event : ''"
                    clearable
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                    :items="status"
                    v-model="search.is_active"
                    v-on:keyup.enter="searchAddressBookCategory"
                    @input="search.is_active = $event !== null ? $event : ''"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    clearable
                    outlined
                    dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" class="text-right">
                <v-btn @click="searchAddressBookCategory()" class="btn btn-primary" :loading="loading">
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <tbody>
                    <tr v-for="(item, index) in categories" :key="index">
                      <td>
                        <a @click="editAddressBookCategory(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                         {{ item.title }}
                        </a>
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editAddressBookCategory(item)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteAddressBookCategory(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="categories.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="7">No Data Found</td>
                    </tr>
                  </tbody>
                </template>

              </table>
              <b-pagination
                  v-if="categories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAddressBookCategories"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  :disabled="loading"
                  first-number
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllAddressBookCategories"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import AddressBookCategoryService from "@/services/address-book/category/AddressBookCategoryService";

const addressBookCategory = new AddressBookCategoryService();

export default {
  components: {
    CreateAndUpdate
  },
  name: "Index",
  data() {
    return{
      loading: false,
      total: null,
      perPage: null,
      page: '',
      categories: [],
      parents: [],
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        title: '',
        is_active: '',
      }
    }
  },
  mounted() {
    this.getAllAddressBookCategories();
  },
  methods: {
    createAddressBookCategory(){
      this.$refs['create-and-update'].createAddressBookCategory();
    },

    editAddressBookCategory(item){
      this.$refs['create-and-update'].editAddressBookCategory(item);
    },

    getAllAddressBookCategories(){
      this.loading = true;
      addressBookCategory
          .paginate(this.search,this.page)
          .then(response => {
            this.categories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchAddressBookCategory(){
      this.getAllAddressBookCategories();
    },

    deleteAddressBookCategory(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            addressBookCategory
                .delete(id)
                .then(() => {
                  this.getAllAddressBookCategories();
                  this.$snotify.success("Address book category deleted successfully");
                })
                .catch(() => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },

}
</script>
