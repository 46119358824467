<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} address book category</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  v-model="addressBookCategory.title"
                  :error="$v.addressBookCategory.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.addressBookCategory.title.$error">Title is required.</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="addressBookCategory.description" >
              </ckeditor>
              <span class="text-danger" v-if="$v.addressBookCategory.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="6" md="6">
              Status
              <v-switch
                  v-model="addressBookCategory.is_active"
                  :label="addressBookCategory.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          {{ edit ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import AddressBookCategoryService from "@/services/address-book/category/AddressBookCategoryService";

const addressBookCategory = new AddressBookCategoryService();

export default {
  name: "CreateAndUpdate",
  validations: {
    addressBookCategory: {
      title: { required },
      description: { required },
      is_active: { required }
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      edit: false,
      loading: false,
      errors: [],
      addressBookCategory: {
        title:'',
        description:'',
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
      },
    }
  },
  methods: {
    closeDialog() {
      this.dialog=false;
    },
    createAddressBookCategory() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editAddressBookCategory(item) {
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.addressBookCategory = item;
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.loading = true;
      addressBookCategory
          .create(this.addressBookCategory)
          .then(response => {
            this.$snotify.success("address book category created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      this.loading = true;
      addressBookCategory
          .update(this.addressBookCategory.id,this.addressBookCategory)
          .then(response => {
            this.$snotify.success("address book Category updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.getAllParent();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.dialog = true;
      this.errors = [];
      this.$v.$reset();
      this.addressBookCategory = {
        title: '',
        description: '',
        is_active: true,
      }
    },

  },
  mounted() {
    this.getAllParent();
  }
}
</script>

<style scoped>

</style>
